import React, { useEffect, useState } from 'react';
import { PreRegistrationApi, PreRegistrationShop, PreRegistration } from './lib/pre-registration-api';
import { StyleOverwrites } from './providers/StyleOverwrites';
import { Header } from './components/header/Header';
import { CustomerDetailsForm } from './components/customer-details-form/CustomerDetailsForm';
import { MainButton } from './components/shared/MainButton';
import { PreRegistrationEventProvider } from './providers/PreRegistrationEventProvider';
import { FormComponent } from './lib/TicketApi';

interface PreRegistrationContainerProps {
    shopSlug: string;
    language?: string;
}

const PageBackground: React.FC<{ shop: PreRegistrationShop; children: React.ReactNode }> = ({ shop, children }) => {
    const hasBackgroundImage = !!shop.style.pageBackgroundImage;
    const style = {
        background: hasBackgroundImage
            ? `url(${shop.style.pageBackgroundImage}) center/cover fixed no-repeat, linear-gradient(to bottom, ${shop.style.pageBackgroundFromColor || '#ffffff'}, ${shop.style.pageBackgroundToColor || '#ffffff'})`
            : `linear-gradient(to bottom, ${shop.style.pageBackgroundFromColor || '#ffffff'}, ${shop.style.pageBackgroundToColor || '#ffffff'})`
    };
    
    return (
        <div className="min-h-screen sm:p-10" style={style}>
            <div className="max-w-[800px] mx-auto rounded-3xl pb-6 shadow-2xl mb-[20px]" style={{
                backgroundColor: shop.style.panelBackgroundColor || '#ffffff',
                color: shop.style.textColor || '#000000',
            }}>
                {children}
            </div>
        </div>
    );
};

export function PreRegistrationContainer({ shopSlug, language = 'en' }: PreRegistrationContainerProps) {
    const [api] = useState(() => new PreRegistrationApi(import.meta.env.VITE_API_URL, shopSlug));
    const [shop, setShop] = useState<PreRegistrationShop | null>(null);
    const [registration, setRegistration] = useState<PreRegistration | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [isFormValid, setIsFormValid] = useState(false);
    const [answers, setAnswers] = useState<Record<string, string[] | object[]>>({});
    const [isSuccess, setIsSuccess] = useState(false);

    useEffect(() => {
        const loadShop = async () => {
            try {
                const shopData = await api.loadShop(language);
                setShop(shopData);
                const registrationData = await api.createRegistration();
                setRegistration(registrationData);
            } catch (e) {
                setError('Failed to load pre-registration');
                console.error(e);
            } finally {
                setIsLoading(false);
            }
        };

        loadShop();
    }, [api, language]);

    const handleSubmit = async () => {
        if (!registration) return;
        setError(null);
        setIsSubmitting(true);
        
        try {
            const updatedRegistration = await api.submitRegistration(answers);
            setRegistration(updatedRegistration);
            
            if (Object.keys(updatedRegistration.errors).length === 0) {
                setIsSuccess(true);
            }
        } catch (e) {
            setError('Failed to submit registration');
            console.error(e);
        } finally {
            setIsSubmitting(false);
        }
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error || !shop || !registration) {
        return <div>Error: {error || 'Failed to load pre-registration'}</div>;
    }

    // Convert array form to OrderedList format
    const formAsOrderedList: Record<number, FormComponent> = {};
    shop.form.forEach((component, index) => {
        formAsOrderedList[index] = component;
    });

    const handleAnswersChange = (newAnswers: Record<string, string | string[] | object[]>) => {
        // Convert single string values to string arrays to match the expected type
        const convertedAnswers: Record<string, string[] | object[]> = {};
        Object.entries(newAnswers).forEach(([key, value]) => {
            if (typeof value === 'string') {
                convertedAnswers[key] = [value];
            } else {
                convertedAnswers[key] = value as string[] | object[];
            }
        });
        setAnswers(convertedAnswers);
    };

    return (
        <StyleOverwrites shopStyle={shop.style}>
            <PreRegistrationEventProvider event={shop.event}>
                <PageBackground shop={shop}>
                    <Header />
                    <main className="px-4 py-8">
                        <div className="bg-[var(--shop-panelBackgroundColor)] rounded-lg shadow-lg p-6">
                            {isSuccess ? (
                                <div className="text-center py-8">
                                    <h2 className="text-2xl font-bold mb-4 text-[var(--shop-textColor)]">
                                        Registration Successful!
                                    </h2>
                                    <p className="text-[var(--shop-textColor)]">
                                        Thank you for your pre-registration. You will receive an email with an exclusive shop link once the sale starts.
                                    </p>
                                </div>
                            ) : (
                                <>
                                    <h2 className="text-2xl font-bold mb-6 text-[var(--shop-textColor)]">
                                        Pre-registration Form
                                    </h2>
                                    <CustomerDetailsForm
                                        form={formAsOrderedList}
                                        answers={answers}
                                        errors={registration.errors}
                                        setAnswers={handleAnswersChange}
                                        setFormValid={setIsFormValid}
                                    />
                                    <div className="mt-6">
                                        <MainButton
                                            onClick={handleSubmit}
                                            disabled={!isFormValid || isSubmitting}
                                            className="w-full"
                                        >
                                            {isSubmitting ? 'Submitting...' : 'Submit Pre-registration'}
                                        </MainButton>
                                    </div>
                                </>
                            )}
                        </div>
                    </main>
                </PageBackground>
            </PreRegistrationEventProvider>
        </StyleOverwrites>
    );
} 