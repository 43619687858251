import {FormPartComponent, OrderDetail} from "@/components/customer-details-form/FormParts/FormParts"
import React, {useEffect} from "react";
import {useForm} from "@mantine/form";
import emailSpellChecker from '@zootools/email-spell-checker';
import {MailSuggestion} from "@zootools/email-spell-checker/dist/lib/types";
import distance from '@zootools/email-spell-checker/dist/lib/helpers/sift3Distance'
import { shopVar } from "@/lib/shopStyles";
import * as m from "@/paraglide/messages.js"

export function OrderDetails({formPart, onValueChange, onValidStatusChange, answers, errors} : FormPartComponent<OrderDetail>) {

    const [suggestions, setSuggestions] = React.useState<MailSuggestion|undefined>(undefined);
    const form = useForm({
        mode: 'controlled',
        initialValues: {
            // @ts-expect-error answers is object
            first_name: answers['first_name'] ?? '',
            // @ts-expect-error answers is object
            last_name: answers['last_name'] ?? '',
            // @ts-expect-error answers is object
            email: answers['email'] ?? '',
            // @ts-expect-error answers is object
            termsOfService: formPart.hasTerms ? (answers['termsOfService'] ?? false) : true,
        },
        validateInputOnChange: [
            'email', 'first_name', 'last_name', ...(formPart.hasTerms ? ['termsOfService'] : [])
        ],
        validate: {
            first_name: (value: string) => (value.length > 0 ? null : m.first_name_is_required()),
            last_name: (value: string) => (value.length > 0 ? null : m.last_name_required()),
            email: (value: string) => (checkEmail(value) ? null : m.invalid_email_format()),
            ...(formPart.hasTerms ? {
                termsOfService: (value: boolean) => (value ? null : m.must_agree_to_tos()),
            } : {}),
        },
        onValuesChange: (values) => {
            onValueChange(formPart.type, values);
            onValidStatusChange(formPart.type, form.isValid());
        }
    });

    useEffect(() => {
        onValidStatusChange(formPart.type, form.isValid());
    }, []);

    const checkEmail = (email: string): boolean => {
        setSuggestions(emailSpellChecker.run({
            email: email,
            distanceFunction: (domain: string, knownDomain: string) => {
                let dist = distance(domain, knownDomain)
                // force prioritize .com matches over .co and .ca
                if (knownDomain === 'com') dist -= 0.75
                return dist
            },
        }));

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    // Helper function to display errors (both client and server-side)
    const getFieldErrors = (fieldName: string) => {
        const clientError = form.errors[fieldName];
        const serverErrors = errors && typeof errors === 'object' && !Array.isArray(errors) ? errors[fieldName] : undefined;
        
        if (clientError) {
            return <p className="mt-2 text-sm text-red-600">{clientError}</p>;
        }
        
        // @ts-expect-error serverErrors is string[]
        if (serverErrors && serverErrors.length > 0) {
            // @ts-expect-error serverErrors is string[]
            return serverErrors.map((error: string, index: number) => (
                <p key={`${fieldName}-error-${index}`} className="mt-2 text-sm text-red-600">{error}</p>
            ));
        }
        
        return null;
    };

    return (
    <div className="grid grid-cols-1 gap-4">
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-4">
            <div className="col-span-1">
                <label htmlFor="first_name" className="block text-sm font-medium leading-6" style={{
                    color: shopVar('--shop-textColor'),
                }}>
                    {m.form_first_name()}
                </label>
                <div className="mt-2">
                    <input
                        id="first_name"
                        name="first_name"
                        type="text"
                        placeholder="John"
                        className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        {...form.getInputProps('first_name')}
                        style={{
                            color: shopVar('--shop-textColor'),
                            backgroundColor: shopVar('--shop-panelBackgroundColor'),
                            // @ts-expect-error --tw-ring-color is defined by tailwindcss
                            '--tw-ring-color': shopVar('--shop-borderColor'), 
                        }}
                    />
                    {getFieldErrors('first_name')}
                </div>
            </div>
            <div className="col-span-1">
                <label htmlFor="last_name" className="block text-sm font-medium leading-6" style={{
                    color: shopVar('--shop-textColor'),
                }}>
                    {m.form_last_name()}
                </label>
                <div className="mt-2">
                    <input
                        id="last_name"
                        name="last_name"
                        type="text"
                        placeholder="Doe"
                        className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        {...form.getInputProps('last_name')}
                        style={{
                            color: shopVar('--shop-textColor'),
                            backgroundColor: shopVar('--shop-panelBackgroundColor'),
                            // @ts-expect-error --tw-ring-color is defined by tailwindcss
                            '--tw-ring-color': shopVar('--shop-borderColor'), 
                        }}
                    />
                    {getFieldErrors('last_name')}
                </div>
            </div>
        </div>
        <div className="">
            <label htmlFor="email" className="block text-sm font-medium leading-6" style={{
                    color: shopVar('--shop-textColor'),
                }}>
                {m.form_email_address()}
            </label>
            <div className="mt-2">
                <input
                    id="email"
                    name="email"
                    type="email"
                    placeholder="you@example.com"
                    className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset 
                     placeholder:text-gray-400 
                     focus:ring-2 focus:ring-inset 
                     focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    {...form.getInputProps('email')}
                    style={{
                        color: shopVar('--shop-textColor'),
                        backgroundColor: shopVar('--shop-panelBackgroundColor'),
                        // @ts-expect-error --tw-ring-color is defined by tailwindcss
                        '--tw-ring-color': shopVar('--shop-borderColor'), 
                    }}
                />
             {suggestions && <div className="font-bold mt-2 text-sm">{m.email_typo_suggestion()}<a onClick={() => form.setFieldValue('email', suggestions.full)} href="#">{suggestions.full}</a>?</div>}
             {getFieldErrors('email')}
            </div>
        </div>
        {formPart.hasTerms && (
            <>
                <div className="relative flex items-start">
                    <div className="flex h-6 items-center">
                        <input
                            id="terms"
                            name="terms"
                            type="checkbox"
                            aria-describedby="terms-description"
                            className="h-4 w-4 rounded border-gray-300 focus:ring-indigo-600"
                            style={{
                                color: '#222222',
                            }}
                            {...form.getInputProps('termsOfService', {type: 'checkbox'})}
                        />
                    </div>
                    <div className="ml-3 text-sm leading-6">
                        <label htmlFor="comments" className="font-medium " style={{
                                color: shopVar('--shop-textColor'),
                            }}>
                            {m.agree_to_terms_pt1()}<a href="https://www.fastlane.events/fastlane-terms-conditions/fastlane-terms-and-conditions-for-ticketing-services" className="font-bold" target="_blank">Fastlane ticketing</a> 
                            {formPart.terms && formPart.terms.termsAndConditionsUrl && <> {m.agree_to_terms_pt2()} <a href={formPart.terms.termsAndConditionsUrl} className="font-bold" target="_blank">{formPart.terms.companyName}</a></>}
                        </label>
                    </div>
                </div>
                {getFieldErrors('termsOfService')}
            </>
        )}
    </div>
    );
}
