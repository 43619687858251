import React, { useState } from 'react';
import { shopVar } from '@/lib/shopStyles';
import clsx from 'clsx';
import { useCart } from '@/providers/CartProvider';
import * as m from "@/paraglide/messages.js"

export const DiscountCodes: React.FC = () => {
    const { applyCoupon, removeCoupon, cart } = useCart();

    const [discountCode, setDiscountCode] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const addDiscountCode = async () => {
        setLoading(true);
        setError('');
        const result = await applyCoupon(discountCode);
        if(result === true){
            setDiscountCode('');
        } else {
            setError(result);
        }

        setLoading(false);
    }

    const doRemoveCoupon = async () => {
        await removeCoupon();
    }

    return (
        <div className='pb-4'>
            {(cart === null || cart?.appliedCouponCode === undefined || cart.appliedCouponCode === null) && (
            <>
                <div className='flex justify-between items-center'>
                    <input type="text" 
                        placeholder={m.coupon_code()}
                        style={{
                            color: shopVar('--shop-textColor'),
                            backgroundColor: shopVar('--shop-panelBackgroundColor'),
                            // @ts-expect-error --tw-ring-color is defined by tailwindcss
                            '--tw-ring-color': shopVar('--shop-borderColor'), 
                        }}
                        className={clsx(
                            'block w-full rounded-md border-0 py-2 shadow-sm ring-1 ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6',
                            {
                                'opacity-60': loading,
                                'pointer-events-none': loading,
                                'ring-red-500': error,
                                'ring-gray-300': !error,
                            }
                        )}
                        value={discountCode}
                        onChange={(e) => setDiscountCode(e.target.value)}
                    />
                    <button 
                        className={clsx(
                            'rounded-md px-4 py-2 ml-2',
                            {
                                'opacity-60': loading || !discountCode,
                                'pointer-events-none': loading || !discountCode,
                            }
                        )}
                        style={{
                            backgroundColor: shopVar('--shop-ctaButtonColor'),
                            color: shopVar('--shop-ctaButtonTextColor'),
                        }}
                        disabled={!discountCode}
                        onClick={addDiscountCode}
                    >{m.add()}</button>
                </div>
                {error && <div className='py-2 text-red-500'>{error}</div>}
            </>
            )}
            {cart!== null && cart.appliedCouponCode !== null && (
                <div className='flex justify-between items-center'>
                    <div className=''>{m.coupon_code()}: <span className='text-lg font-bold'>{cart?.appliedCouponCode?.code}</span></div>
                    <button className='hover:underline' onClick={() => doRemoveCoupon()}>{m.remove()}</button>
                </div>
            )}
        </div>
    );
};