'use client';

import React, {createContext, useCallback, useContext, useEffect, useRef, useState} from 'react';
import {debounce} from '@/lib/debounce';
import {Cart as ApiCart, Coupon, getProduct, OrderedList, Payment, PaymentMethod, PaymentResponse, PaymentStatus, ReserveProductError, TicketApi} from '@/lib/TicketApi'
import { Money } from '@/lib/money';
import {ulid} from "ulidx";
import { useShop } from './ShopProvider';
import { calculateFee } from './../lib/calculateFee';
import { eventEmitter } from '@/lib/eventEmitter';
import { useTracking } from '@/tracking/context';

// Define the context
const CartContext = createContext<CartContextType|null>(null);

export interface FormComponent {
    type: string;
    key: string;
}

interface Cart {
    id: string;
    products: { productId: string; quantity: number; }[];
    form: OrderedList<FormComponent>,
    answers: object;
    errors: string[]|string[][];
    payments: {[paymentId: string]: Payment;}
    isCheckedOut: boolean;
    pendingPayment: string|null;
    orderId: string|null;
    appliedCouponCode: Coupon|null;
    checkoutAtEventId: string|null;
}

export interface ProductSelectionWithPrice {
    productId: string;
    quantity: number;
    name: string;
    unit: Money;
    unitInclFee: Money;
    total: Money;
    feeUnit: Money|null;
    feeTotal: Money|null;
    totalInclFee: Money;
}

interface CartContextType {
    cart: Cart | null;
    loading: boolean;
    setProductQuantity: (productId: string, quantity: number) => void;
    applyCoupon: (discountCode: string) => Promise<string|true>;
    removeCoupon: () => Promise<void>;
    selectedQuantity: (productId: string) => number;
    totalForProduct: (productId: string) => null|ProductSelectionWithPrice;
    error: string;
    costOverview: () => CostOverview;
    productError: (productId: string) => string|null;
    setStep: (step: CartStep) => void;
    cartStep: CartStep,
    submitFormAnswers: () => Promise<ApiCart | null>;
    setFormValid: (valid: boolean) => void;
    formIsValid: boolean;
    setFormState: (state: object) => void;
    formState: object|null;
    setPaymentMethod: (paymentMethod: string) => void;
    paymentMethod: string|null;
    startCheckout: (eventId: string) => Promise<PaymentResponse>;
    currentPayment: () => Payment | null;
    clearServerError: (key: string) => void;
    serverErrors: Record<string, string[]>;
}

interface CartProviderProps {
    children: React.ReactNode,
}

interface CostOverview {
    total: Money;
    totalExPaymentFee: Money;
    products: ProductSelectionWithPrice[];
    fee: { name: string; total: Money } | null;
    paymentFee: { name: string; total: Money } | null;
}

export enum CartStep {
    Tickets = 'tickets',
    Order = 'Order',
    Checkout = 'checkout',
    PaymentReturn = 'payment-return',
}

export const CartProvider: React.FC<CartProviderProps> = ({children}) => {
    const [ticketApi, setTicketApi] = useState<TicketApi|null>(null);
    const [cart, setCart] = useState<Cart|null>(null);
    const cartRef = useRef<Cart|null>(null);
    const previousEventSlugRef = useRef<string|undefined>(undefined);
    const [paymentMethod, setPaymentMethod] = useState<string|null>(null);
    const [serverErrors, setServerErrors] = useState<Record<string, string[]>>({});

    const {shop, shopSlug, baseUrl, selectedEventSlug, setSelectedEventSlug} = useShop();
    const { trackEvent } = useTracking();

    const [loading, setLoading] = useState(false);

    const lastReportedStep = useRef(CartStep.Tickets);

    const [error, setError] = useState('');

    const [step, setStep] = useState(CartStep.Tickets)

    const [productErrors, setProductErrors] = useState<{[productId: string]: string}>({});

    const [formState, setFormState] = useState<object|null>(null);
    const [formValid, setFormValid] = useState<boolean>(false);

    // Clear cart when switching to a different event
    // in the future we should support multiple events in the same cart
    // for now it gives issues on the order page
    useEffect(() => {
        // Only clear if we're switching between actual events (not when going to/from index)
        if (previousEventSlugRef.current !== undefined && 
            selectedEventSlug !== undefined && // only when switching TO an event
            selectedEventSlug !== previousEventSlugRef.current && 
            cart !== null && 
            cart.pendingPayment === null && 
            cart.isCheckedOut === false && 
            cart.products.length > 0) {
                resetCart();
        }

        if(selectedEventSlug !== undefined){
            previousEventSlugRef.current = selectedEventSlug;
        }
    }, [selectedEventSlug]);

    const handleFormStateChange = (state: object) => {
        // Clear server errors for any fields that are being changed
        Object.keys(state).forEach(key => {
            if (serverErrors[key]) {
                clearServerError(key);
            }
        });
        setFormState(state);
    };

    useEffect(() => {
        if(typeof window === 'undefined'){
            return;
        }
        const searchParams = new URLSearchParams(window.location.search);
        const cartIdFromUrl = searchParams.get('cart_id');
        if (cartIdFromUrl && typeof cartIdFromUrl === 'string') {
            fetchCart(cartIdFromUrl);
            setStep(CartStep.Checkout);
        }
    }, [ticketApi]);

    useEffect(() => {
        if (lastReportedStep.current === step) {
            return;
        }

        trackEvent({
            type: 'PAGE_VIEW',
            payload: {
                shopId: shopSlug,
                path: window.location.pathname + "#" + step,
                title: step,
                params: {
                    innerRouter: step,
                },
            },
        });

        lastReportedStep.current = step;

    
        // Trigger iframe resize after step change
        // this is for pages that use eventix iframe integration
        setTimeout(() => {
            // @ts-expect-error defined in iframeResizer.contentWindow.min.js
            if (window.parentIFrame) {
                // @ts-expect-error defined in iframeResizer.contentWindow.min.js
                window.parentIFrame.size();
            }
        }, 100);

    }, [step, shopSlug, cart?.id, trackEvent]);

    useEffect(() => {
        cartRef.current = cart;
    }, [cart]);

    useEffect(() => {
       if(shop.paymentMethods.length > 0){
            setPaymentMethod(shop.paymentMethods[0].id);
       }
    }, [shop]);

    // Function to fetch order
    const fetchCart = async (cartId: string, inBackground: boolean = false, pageLoadFetch: boolean = false): Promise<ApiCart|null> => {
        if(ticketApi === null){
            return null;
        }
        if(!inBackground){
            setLoading(true);
        }
        try {
            const cart: ApiCart = await ticketApi.loadCart(cartId);
            updateCart(cart);

            if(pageLoadFetch && Object.keys(cart.payments).length > 0){
                setStep(CartStep.Checkout);
                if (cart.checkoutAtEventId && shop) {
                    const event = shop.events.find(event => event.eventId === cart.checkoutAtEventId);
                    if (event) {
                        console.log('open event slug', event.slug);
                        window.dispatchEvent(new CustomEvent('fastlane_openWidget', {
                            detail: {
                                shopSlug,
                                eventSlug: event.slug
                            }
                        }));
                    }
                } else {
                    console.log('open widget without event slug');
                    window.dispatchEvent(new Event('fastlane_openWidget'));
                }
            }
            return cart;
        } catch (error) {
            console.error('Failed to load cart', error);
            // unset order if it fails to load
            if(!inBackground){
                resetCart();
                setLoading(false);
                setStep(CartStep.Tickets);
                return null;
            }
        } finally {
            if(!inBackground){
                setLoading(false);
            }
        }
        return null;
    };

    const updateCart = (cart: ApiCart) => {
        if(cart.timedOut){
            resetCart();
            return;
        }

        // If we have a checkoutAtEventId, find and set the corresponding event slug
        if (cart.checkoutAtEventId && shop) {
            const event = shop.events.find(event => event.eventId === cart.checkoutAtEventId);
            if (event) {
                setSelectedEventSlug(event.slug);
            }
        }

        setCart({
            id: cart.cartId,
            products: cart.products,
            form: cart.form,
            answers: cart.answers,
            errors: cart.errors,
            payments: cart.payments,
            isCheckedOut: cart.isCheckedOut,
            pendingPayment: cart.pendingPayment,
            orderId: cart.orderId,
            appliedCouponCode: cart.appliedCouponCode,
            checkoutAtEventId: cart.checkoutAtEventId,
        });

        // Update server errors state
        if (typeof cart.errors === 'object' && !Array.isArray(cart.errors)) {
            setServerErrors(cart.errors as Record<string, string[]>);
        } else {
            setServerErrors({});
        }
        
        // todo, only emit if coupon is applied and products have changed since last time
        eventEmitter.emit('RELOAD_SHOP_CONFIG', {
            shopSlug: shopSlug,
            unlockedProductIds: (cart.appliedCouponCode?.unlockProducts?.products ?? []).map((p: { productId: string }) => p.productId),
        });
    }

    useEffect(() => {
        if (cart?.pendingPayment) {
            const startTime = Date.now();
            const interval = setInterval(() => {
                if (Date.now() - startTime > 45000) { // 45 seconds
                    clearInterval(interval);
                    cancelPayment(); // Call the retry method
                } else {
                    fetchCart(cart.id, true);
                }
            }, 3000);

            return () => clearInterval(interval);
        }
    }, [cart?.pendingPayment]);

    const cancelPayment = async () => {
        if(ticketApi === null || cart === null || cart.pendingPayment === null){
            return;
        }
        await ticketApi.forceCancelPendingPayment(cart.id, cart.pendingPayment);
        // wait for cart to be updated
        const checkCartUpdate = async () => {
            const newCart = await fetchCart(cart.id, true);
            if (newCart === null || newCart.pendingPayment === null) {
                return;
            }
            setTimeout(checkCartUpdate, 5000);
        };

        checkCartUpdate();
    };

    useEffect(() => {
        if(cart === null){
            return;
        }

        if (cart.pendingPayment) {
            setPaymentMethod(cart.payments[cart.pendingPayment].methodId);
            setStep(CartStep.Checkout);

            // open widget with event slug if available
            if (cart.checkoutAtEventId && shop) {
                const event = shop.events.find(event => event.eventId === cart.checkoutAtEventId);
                if (event) {
                    console.log('open widget with event slug', event.slug);
                    window.dispatchEvent(new CustomEvent('fastlane_openWidget', {
                        detail: {
                            shopSlug,
                            eventSlug: event.slug
                        }
                    }));
                }
            } else {
                console.log('open widget without event slug');
                window.dispatchEvent(new Event('fastlane_openWidget'));
            }
        }

        if(cart.isCheckedOut){
            trackEvent({
                type: 'PURCHASE',
                payload: {
                    shopId: shopSlug,
                    currency: costOverview().total.currency,
                    transactionId: cart.id,
                    cartId: cart.id,
                    items: cart.products.map(p => ({
                        productId: p.productId,
                        quantity: p.quantity,
                        price: (getProduct(shop, p.productId)?.price.amount ?? 0) / 100,
                        name: getProduct(shop, p.productId)?.name ?? '',
                    })),
                    totalValue: costOverview().total.amount/100,
                },
            });
            // remove cart from localStorage
            localStorage.removeItem(`cart-${shopSlug}`);
            // set payment method used to display the right order summary
            const paymentMethod = Object.values(cart.payments).find(p => p.status === PaymentStatus.Paid);
            if(paymentMethod !== undefined){
                setPaymentMethod(paymentMethod.methodId);
            }
            setStep(CartStep.Checkout);
            if (cart.checkoutAtEventId && shop) {
                const event = shop.events.find(event => event.eventId === cart.checkoutAtEventId);
                if (event) {
                    window.dispatchEvent(new CustomEvent('fastlane_openWidget', {
                        detail: {
                            shopSlug,
                            eventSlug: event.slug
                        }
                    }));
                }
            } else {
                window.dispatchEvent(new Event('fastlane_openWidget'));
            }
        }
    }, [cart]);

    // Load orderId from localStorage and fetch order on mount
    useEffect(() => {
        if(shopSlug === undefined || ticketApi === null){
            return;
        }
        const cartId = localStorage.getItem(`cart-${shopSlug}`);
        if (cartId) {
            fetchCart(cartId, false, true);
        }
    }, [shopSlug, ticketApi, shop]);

    useEffect(() => {
        setTicketApi(new TicketApi(baseUrl, shopSlug));
    }, [baseUrl, shopSlug]);

    const resetCart = () => {
        setCart(null);
        setStep(CartStep.Tickets);
        localStorage.removeItem(`cart-${shopSlug}`);
    }

    const currentPayment = (): Payment | null => {
        if(cart === null){
            return null;
        }

        // get transaction id from url transactionid
        const searchParams = new URLSearchParams(window.location.search);
        const paymentId = searchParams.get('transactionid');
        if (!paymentId || typeof paymentId !== 'string') {
            return null;
        }

        const payment = cart.payments[paymentId];
        if(payment === undefined){
            return null;
        }

        return payment;
    }

    const reserveProducts = useCallback(debounce(async (trackingEventId?: string) => {
        if(ticketApi === null || cartRef.current === null){
            throw new Error('TicketApi or order is null');
        }
        try {
            setLoading(true);
            const cartOrError = await ticketApi.reserveProducts(cartRef.current?.id, cartRef.current?.products, trackingEventId);

            if (Object.values(ReserveProductError).includes(cartOrError as ReserveProductError)) {
                if(cartOrError === ReserveProductError.CartTimeout){
                    resetCart();
                    return;
                }
                throw new Error('Failed to reserve products');
            }

            const cart = cartOrError as ApiCart;

            cartRef.current?.products.forEach(p => {// check if product is reserved, otherwise set product error
                const reservedProduct = cart.products.find(rp => rp.productId === p.productId);
                if((reservedProduct === undefined || reservedProduct.quantity !== p.quantity) && p.quantity > 0){
                    setProductErrors({...productErrors, [p.productId]: `Could not reserve ${p.quantity} ticket(s) for this product. All inventory is reserved, select a different product or try again later.`});
                }
            })

            // update cart
            updateCart(cart);

            setLoading(false);
        } catch (error) {
            setError('Failed to reserve product');
            // reload cart..
            fetchCart(cartRef.current.id);
        } finally {
            setLoading(false);
        }
    }, 800), [ticketApi]);  
    // Update product quantity immediately and debounce the reservation
    const setProductQuantity = (productId: string, quantity: number) => {
        // remove product error if it exists
        if(productErrors[productId] !== undefined){
            const newProductErrors = {...productErrors};
            delete newProductErrors[productId];
            setProductErrors(newProductErrors);
        }

        const cartId = cart?.id ?? ulid();
        const trackingEventId = `atc_${cartId}_${Date.now()}`;

        let delta = quantity;
        if(cart === null){
            const newCart = {
                id: cartId,
                products: [{productId, quantity}],
                form: [],
                answers: {},
                errors: [],
                payments: {},
                isCheckedOut: false,
                pendingPayment: null,
                orderId: null,
                appliedCouponCode: null,
                checkoutAtEventId: null,
            };
            setCart(newCart);
            localStorage.setItem(`cart-${shopSlug}`, newCart.id);
        } else {
            delta = quantity - selectedQuantity(productId);
            // Create a new array with updated products to ensure immutability
            const updatedProducts = cart.products.map(p =>
                p.productId === productId ? { ...p, quantity } : p
            );

            // If product does not exist in the order, add it
            if (!updatedProducts.some(p => p.productId === productId)) {
                updatedProducts.push({productId, quantity});
            }

            // Now update the order with a new object, ensuring we change the reference
            setCart({ ...cart, products: updatedProducts });
        }

        if(delta > 0){
            trackEvent({
                type: 'ADD_TO_CART',
                payload: {
                    shopId: shopSlug,
                    cartId: cartId,
                    productId,
                    name: getProduct(shop, productId)?.name ?? '',
                    price: getProduct(shop, productId)?.price.amount ?? 0,
                    quantity: delta,
                    eventId: trackingEventId,
                },
            });
        }
        else {
            trackEvent({
                type: 'REMOVE_FROM_CART',
                payload: {
                    shopId: shopSlug,
                    cartId: cartId,
                    productId,
                    name: getProduct(shop, productId)?.name ?? '',
                    price: getProduct(shop, productId)?.price.amount ?? 0,
                    quantity: -delta,
                    eventId: trackingEventId,
                },
            });
        }

        reserveProducts(trackingEventId);
    }

    const applyCoupon = async (discountCode: string): Promise<string|true> => {

        const cartId = cart === null ? ulid() : cart.id;
        if(cart === null){
            const newCart = {
                id: cartId,
                products: [],
                form: [],
                answers: {},
                errors: [],
                payments: {},
                isCheckedOut: false,
                pendingPayment: null,
                orderId: null,
                appliedCouponCode: null,
                checkoutAtEventId: null,
            };
            setCart(newCart);
            localStorage.setItem(`cart-${shopSlug}`, newCart.id);
        } 

    
        if(ticketApi === null){
            return "failed to apply coupon";
        }

        const newCart = await ticketApi.applyCoupon(cartId, discountCode);
        if(typeof newCart === 'string'){
            return newCart;
        }
        updateCart(newCart);
        return true;
    }

    const removeCoupon = async () => {
        if(ticketApi === null || cart === null){
            return;
        }

        // for now refresh cart and reload page
        resetCart();
        window.location.reload();
    }

    // Utility to get selected quantity for a specific product ID
    const selectedQuantity = (productId: string) => {
        const product = cart?.products.find(p => p.productId === productId);
        return product ? product.quantity : 0;
    };

    const totalForProduct = (productId: string): ProductSelectionWithPrice|null => {
        selectedQuantity(productId);

        if(shop === null){
            return null;
        }

        const product = getProduct(shop, productId);
        const quantity = selectedQuantity(productId);
        if(product === undefined){
            return null;
        }

        const unit = Money.fromInteger(product.price.amount, product.price.currency);
        const productTotal = unit.multiply(quantity);

        // add fee
        let serviceFee: null | Money = null;
        let serviceFeeUnit: null | Money = null;
        let unitInclFee: Money = unit;
    
        let totalInclFee = productTotal;
        if(product.serviceFee?.amountPerUnit !== undefined && product.serviceFee?.amountPerUnit !== null){
            serviceFeeUnit = Money.fromInteger(product.serviceFee.amountPerUnit.amount, product.serviceFee.amountPerUnit.currency);
            serviceFee = serviceFeeUnit.multiply(quantity);
            totalInclFee = totalInclFee.add(serviceFee);
            unitInclFee = unit.add(serviceFeeUnit);
        }

        return {productId, quantity, name: product.name, unit, total: productTotal, feeUnit: serviceFeeUnit, feeTotal: serviceFee, totalInclFee, unitInclFee};
    }

    const productError = (productId: string): string|null => {
        return productErrors[productId] ?? null;
    }

    const costOverview = (): CostOverview => {
        if(shop === null || cart === null){
            return {
                total: Money.zero(shop?.defaultCurrency ?? 'EUR'),
                totalExPaymentFee: Money.zero(shop?.defaultCurrency ?? 'EUR'),
                products: [],
                fee: null,
                paymentFee: null,
            };
        }

        let totalExPaymentFee = Money.zero(shop.defaultCurrency);
        let fee = Money.zero(shop.defaultCurrency);

        const products = cart.products.map(p => {
            const product = totalForProduct(p.productId);
            if(product === null){
                return null;
            }
            totalExPaymentFee = totalExPaymentFee.add(product.total);
            if(product.feeTotal !== null){
                fee = fee.add(product.feeTotal);
            }
            return product;
        }).filter(p => p !== null);

        totalExPaymentFee = totalExPaymentFee.add(fee);

         // add payment fee
         const selectedPaymentMethod: PaymentMethod|undefined = shop.paymentMethods.find(pm => pm.id === paymentMethod);

         let paymentFee = Money.zero(shop.defaultCurrency);

         let total = totalExPaymentFee;

         if(step !== CartStep.Tickets && step !== CartStep.Order && !totalExPaymentFee.isZero()){
            if(selectedPaymentMethod?.fee !== undefined){
            paymentFee = calculateFee(selectedPaymentMethod.fee, total, 1);
            total = total.add(paymentFee);
            }
        }

        return {
            total,
            totalExPaymentFee,
            products,
            fee: fee.isZero() ? null : { name: 'Service Fee', total: fee},
            paymentFee: paymentFee.isZero() ? null : { name: selectedPaymentMethod?.fee?.name ?? 'Payment Fee', total: paymentFee},
        };
    }

    const submitFormAnswers = async () => {
        if(cart === null){
            throw new Error('Cart is null');
        }

        if (ticketApi === null) {
            throw new Error('TicketApi is null');
        }

        try {
            setLoading(true);
            const result = await ticketApi.setFormAnswers(cart.id, formState ?? {});
            updateCart(result);
            setLoading(false);
            return result;
        } catch (err) {
            const error = err as Error;
            console.error('Failed to set form answers:', error);
            setError(error?.message ?? 'Failed to set form answers');
            setLoading(false);
            return null;
        }
    }

    // event id is now passed, in the future it might be retreived from another provider
    const startCheckout = async (eventId: string): Promise<PaymentResponse> => {
        if(cart === null){
            throw new Error('Cart is null');
        }

        if (ticketApi === null) {
            throw new Error('TicketApi is null');
        }

        const trackingEventId = `begin_checkout_${cart.id}_${Date.now()}`;

        try {
            setLoading(true);

            trackEvent({
                type: 'BEGIN_CHECKOUT',
                payload: {
                    shopId: shopSlug,
                    currency: costOverview().total.currency,
                    cartId: cart.id,
                    eventId: trackingEventId,
                    items: cart.products.map(p => ({
                        productId: p.productId,
                        quantity: p.quantity,
                        price: (getProduct(shop, p.productId)?.price.amount ?? 0) / 100,
                        name: getProduct(shop, p.productId)?.name ?? '',
                    })),
                    totalValue: costOverview().total.amount/100,
                },
            });

            const paymentResponse = await ticketApi.checkout({
                cartId: cart.id,
                eventId: eventId,
                paymentMethodId: paymentMethod,
                redirectUrl: null,
                trackingEventId: trackingEventId
            });

            if(paymentResponse.type === 'no_payment_required') {
                // Start polling for order completion
                const pollInterval = setInterval(async () => {
                    const updatedCart = await ticketApi.loadCart(cart.id);
                    if (updatedCart.orderId) {
                        clearInterval(pollInterval);
                        updateCart(updatedCart);
                        setLoading(false);
                    }
                }, 500); // Poll every .5 seconds

                // Set a timeout to stop polling after a certain time (e.g., 5 minutes)
                setTimeout(() => {
                    clearInterval(pollInterval);
                    if (!cartRef.current?.orderId) {
                        setError('Order processing timed out. Please check your order status later.');
                        setLoading(false);
                    }
                }, 300000); // 5 minutes timeout
                return paymentResponse;
            }

            setLoading(false);
            return paymentResponse;
        } catch (error) {
            setLoading(false);
            throw error;
        }
    }

    const clearServerError = (key: string) => {
        setServerErrors(prev => {
            const newErrors = { ...prev };
            delete newErrors[key];
            return newErrors;
        });
    };

    const contextValue: CartContextType = {
        cart: cart,
        loading,
        setProductQuantity,
        selectedQuantity,
        totalForProduct,
        error,
        costOverview,
        productError,
        setStep: setStep,
        cartStep: step,
        submitFormAnswers: submitFormAnswers,
        setFormValid: setFormValid,
        formIsValid: formValid,
        setFormState: handleFormStateChange,
        formState: formState,
        setPaymentMethod: setPaymentMethod,
        paymentMethod: paymentMethod,
        startCheckout: startCheckout,
        currentPayment: currentPayment,
        applyCoupon: applyCoupon,
        removeCoupon: removeCoupon,
        clearServerError: clearServerError,
        serverErrors: serverErrors,
    };

    return (
        <CartContext.Provider value={contextValue}>
            {children}
        </CartContext.Provider>
    );
};

// Custom hook to use order context
export const useCart = () => {
    const context = useContext(CartContext);
    if (context === null) {
        throw new Error('useCart must be used within a CartProvider');
    }
    return context;
};
