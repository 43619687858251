import { createFileRoute } from '@tanstack/react-router';
import { EventContainer } from '@/EventContainer';

export const Route = createFileRoute('/$slug/_layout/$event')({
  component: Event,
});

function Event() {
  const { event } = Route.useParams();
  return <EventContainer eventSlug={event} />;
} 
