import { OrderedList, FormComponent, ShopStyle } from "./TicketApi";

export interface PreRegistrationEvent {
    eventId: string;
    name: string;
    headerImage?: string;
    location: {
        name: string;
    };
    startDate: string;
    endDate: string;
}

export interface Language {
    code: string;
    name: string;
    flagIcon: string;
}

export interface PreRegistrationShop {
    slug: string;
    defaultLanguage: string;
    supportedLanguages: Language[];
    style: ShopStyle;
    event: PreRegistrationEvent;
    form: FormComponent[];
}

export interface PreRegistration {
    registrationId?: string;
    form: OrderedList<FormComponent>;
    answers: Record<string, string | string[] | object[]>;
    errors: Record<string, string[]>;
}

interface ApiResponse<T> {
    data: T;
}

interface ApiErrorResponse {
    errors: Record<string, string[]>;
}

export class PreRegistrationApi {
    constructor(
        private readonly baseUrl: string,
        private readonly shopSlug: string,
    ) {}

    public async loadShop(language: string): Promise<PreRegistrationShop> {
        const response = await fetch(`${this.baseUrl}/pre-registration/${this.shopSlug}?language=${language}`);
        if (!response.ok) {
            throw new Error('Failed to load pre-registration shop');
        }
        const json = await response.json() as ApiResponse<PreRegistrationShop>;
        return json.data;
    }

    public async createRegistration(): Promise<PreRegistration> {
        // Convert array form to OrderedList format
        const shop = await this.loadShop('en'); // Use default language for initial form
        const formAsOrderedList: Record<number, FormComponent> = {};
        shop.form.forEach((component, index) => {
            formAsOrderedList[index] = component;
        });

        return {
            form: formAsOrderedList,
            answers: {},
            errors: {}
        };
    }

    public async submitRegistration(answers: Record<string, string | string[] | object[]>): Promise<PreRegistration> {
        const response = await fetch(`${this.baseUrl}/pre-registration/${this.shopSlug}/register`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ answers }),
        });

        const data = await response.json();

        if (!response.ok) {
            const errorResponse = data as ApiErrorResponse;
            // Return a PreRegistration object with the errors
            const shop = await this.loadShop('en'); // Use default language for form structure
            const formAsOrderedList: Record<number, FormComponent> = {};
            shop.form.forEach((component, index) => {
                formAsOrderedList[index] = component;
            });

            return {
                form: formAsOrderedList,
                answers,
                errors: errorResponse.errors
            };
        }

        // If successful, return a clean PreRegistration object
        return {
            form: {}, // Form not needed after successful submission
            answers: {},
            errors: {}
        };
    }
} 